import React, { useState } from 'react';
import urlProcess from '../utils/url-process';
import { useStaticQuery, graphql, Link } from 'gatsby';

import '../styles/menu.scss';

const ListLink = ({ to, children }) => (
  <li>
    <Link to={to}>{children}</Link>
  </li>
);

const Menu = () => {
  const [isActiveCategories, setCategoriesStatus] = useState(false);
  const [isMobileMenuBtnActive, setMobileMenuBtnStatus] = useState(true);

  const handleCategoriesClick = () => {
    setCategoriesStatus(!isActiveCategories);
  };

  const handleMobileMenuBtnClick = () => {
    setMobileMenuBtnStatus(!isMobileMenuBtnActive);
  };

  const menuClass = isActiveCategories ? 'active' : '';
  const mobileMenuBtnClass = isMobileMenuBtnActive ? 'hidden' : '';

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___categories) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  return (
    <nav className="nav-wrapper">
      <div
        onClick={handleMobileMenuBtnClick}
        className="mobileMenuBtn"
        onKeyDown={handleMobileMenuBtnClick}
        role="button"
        tabIndex="0"
      >
        &#9776;
      </div>
      <ul className={'main-nav ' + mobileMenuBtnClass}>
        <ListLink to="/blog">Articles</ListLink>
        <li className="menu-item-toggle">
          <div onClick={handleCategoriesClick} onKeyDown={handleCategoriesClick} role="button" tabIndex="0">
            Categories
          </div>
          <SubMenu menuClass={menuClass} data={allMarkdownRemark} />
        </li>
        <ListLink to="/cv">CV</ListLink>
        <ListLink to="/contacts">Contacts</ListLink>
      </ul>
      <SubMenu menuClass={menuClass} data={allMarkdownRemark} />
    </nav>
  );
};

export const SubMenu = ({ menuClass, data }) => (
  <ul className={'sub-nav ' + menuClass}>
    <li>
      <strong>Categories: </strong>
    </li>
    {data.group.map((category) => (
      <ListLink key={category.fieldValue} to={`/categories/${urlProcess(category.fieldValue)}/`}>
        {category.fieldValue}
      </ListLink>
    ))}
  </ul>
);

export default Menu;
