import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import DarkModeSwitch from '../components/dark-mode-switch';
import rss from '../images/rss.svg';
import linkedin from '../images/linkedin.svg';
import Menu from './menu';

import '../styles/header.scss';

const Header = ({ smallHeader }) => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Pantaley-Stoyanov.jpg" }) {
        childImageSharp {
          fixed60: fixed(width: 60, height: 60, toFormat: WEBP, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          fixed40: fixed(width: 44, height: 44, toFormat: WEBP, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Fragment>
      <header className={`main-header ${smallHeader ? 'small' : ''}`}>
        <Link to="/" aria-label="Go to home page">
          <Img
            fixed={smallHeader ? file.childImageSharp.fixed40 : file.childImageSharp.fixed60}
            loading="eager"
            fadeIn={false}
          />
        </Link>
        <div className="profile-title">
          <Link to="/" aria-label="Go to home page">
            <h2 style={{ lineHeight: smallHeader ? '20px' : '30px' }}>Pantaley Stoyanov</h2>
          </Link>
          <div className="profile-links">
            <a
              style={{ width: smallHeader ? '20px' : '30px', height: smallHeader ? '20px' : '30px' }}
              href="https://www.linkedin.com/in/pantaley/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              style={{ width: smallHeader ? '20px' : '30px', height: smallHeader ? '20px' : '30px' }}
              href="/rss.xml"
              target="_blank"
            >
              <img src={rss} alt="rss" />
            </a>
          </div>
        </div>
        <DarkModeSwitch />
      </header>
      <Menu />
    </Fragment>
  );
};

export default Header;
