import React, { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import 'normalize.css';
import 'prismjs/themes/prism.css';

import '../styles/layout.scss';

import Header from './header.js';

import favicon from '../images/favicon.png';
import featuredImage from '../images/pantaley.com.png';

const Layout = ({ children, postData, smallHeader }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          excerpt
          siteUrl
        }
      }
    }
  `);
  const metaData = postData || site.siteMetadata;
  return (
    <StrictMode>
      <Helmet>
        <html lang="en" />
        <title>{`${metaData.title} | ${site.siteMetadata.title}`}</title>
        <link rel="shortcut icon" href={favicon} type="image/png" />

        <meta property="og:title" content={`${metaData.title} | ${site.siteMetadata.title}`} />
        <meta property="og:description" content={metaData.excerpt} />
        <meta property="og:image" content={metaData.featuredImage ? metaData.featuredImage.publicURL : featuredImage} />

        <meta property="fb:app_id" content="389103081651332" />
        <meta name="description" content={metaData.excerpt} />
      </Helmet>
      <div className="container">
        <Header smallHeader={smallHeader} />
        <div className="content">{children}</div>
        <footer className="copyright">&copy; {new Date().getFullYear()} All rights reserved.</footer>
      </div>
    </StrictMode>
  );
};

export default Layout;
